//Mobile/Global design
//--global
.linkFix {
  text-decoration: none;
  color: black;
}

#banner {
  margin: 10px;
  text-align: center;
  padding-top: 70px;

  #fixedBanner {
    position: fixed;
    background-color: #fafafa;
    height: 70px;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 99;

    .menuIcon {
      position: fixed;
      top: 0;
      left: 0;
      margin: 7px;
      padding: 7px;
    }

    img {
      position: fixed;
      margin-left: -121px;
      left: 50%;
      max-width: 242px;
    }

    #desktopText {
      display: none;
    }

    #bannerDivider {
      margin: 0 25px;
      margin-top: 70px;
    }
  }

  #desktopNav {
    display: none;
  }

  .mobileContactInfo {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    grid-column-gap: 2px;
    margin-bottom: 5px;

    .mobileText {
      margin: 5px 0;
    }
  }
}

#mobileNavContactButton {
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto;
  grid-column-gap: 40px;
  margin: 10px 0;
}

//--pages
#home {
  #swiperComponent {
    margin: 0 20px;
  }

  .swiperSlide {
    position: relative;

    img {
      width: 100%;
    }

    p {
      color: white;
      position: absolute;
      bottom: 5px;
      left: 8px;
      padding: 2px;
      background-color: rgba(7, 7, 7, 0.753);
    }
  }

  #homeContent {
    margin: 20px;

    h1 {
      text-align: center;
    }

    .homeInfoCard {
      margin: 10px 0;

      h4 {
        text-align: center;
        margin: 10px 15px;
      }

      .homeCardIcon {
        float: left;
        margin: 10px;
      }

      p {
        margin: 20px 15px;
      }

      .readMoreButton {
        text-align: center;
      }
    }
  }
}

#cilVlak {
  #cilVlakContent {
    margin: 0 20px;

    #cilVlakBanner {
      display: none;
    }

    #cilKopVlakken {
      float: right;
      margin: 20px 0 40px 30px;
    }
  }
}

#cilVlakTransport {
  #cilVlakTransportContent {
    margin: 0 20px;

    p {
      margin: 20px;
    }

    #cilKopTransportImage {
      float: left;
      margin: 10px 20px 10px 0px;
    }

    #transportCards {
      margin-top: 30px;
      width: 100%;
      display: grid;
      grid-template-columns: auto;
      text-align: center;

      .sideCardContent {
        margin: 10px;
        height: 300px;
        max-width: 100%;
      }

      #midCard {
        margin: 10px 0;
        padding: 7px;
      }

      .sideCard {
        padding: 7px;
      }

      .midCardContent {
        margin: 10px;
        height: 300px;
        max-width: 100%;
      }

      .transportTitleCard {
        margin: auto;
        width: 90%;
        max-width: 100%;
      }

      .transportPrice {
        text-align: center;
        margin: 40px;

        .euroSign {
          display: inline-block;
          font-size: 1rem;
          vertical-align: super;
        }
      }
    }
  }
}

//--components
#services {
  margin-top: 20px;

  h4 {
    text-align: center;
    margin: 10px 15px;
  }

  ul {
    list-style-type: "- ";
  }

  #serviceRequestButton {
    text-align: center;
  }
}

#jachtOnderhoud {
  text-align: center;

  #jachtOnderhoudServiceCards {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: auto;
  }

  .jachtOnderhoudServiceCard {
    width: 90%;
    margin: 5px 5%;
    padding: 10px;

    img {
      width: 120%;
      margin-left: -10%;
      text-align: center;
    }
  }

  #dealerCard {
    margin: 10px;
    border: 1px solid #222222;

    h3 {
      margin: 0;
    }

    #dealerImages {
      display: grid;
      justify-content: space-evenly;
      grid-template-columns: auto auto;

      img {
        max-width: 120%;
        margin: 20px;
        margin-left: calc(-10% + 20px);
      }
    }
  }
}

#contact {
  .contactTab {
    margin: 16px 0;

    ul {
      list-style: none;
      margin-left: 20px;

      .contactListIcon {
        width: 2em;
        height: 2em;
        position: relative;
        left: -10px;
        margin-left: -2em;
        top: 10px;
      }
    }
  }
}

#serviceVerzoek {
  text-align: center;

  .serviceVerzoekForm {
    margin-top: 30px;
    margin-bottom: 20px;

    .formTitle {
      margin-top: 20px;
    }

    .textInput {
      margin: 5px;
      width: 80%;
    }
  }
}

#webshop {
  #webshopTitle,
  #webshopDescription {
    text-align: center;
  }

  #mainBody {
    margin: 20px;
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: auto;
    grid-gap: 10px;

    .webProduct {
      position: relative;
      padding: 10px;
      padding-bottom: 30px;

      .webProductTitle {
        text-align: center;
        margin: 5px 0;
      }

      img {
        width: 100%;
      }

      .webProductDescription {
        margin: 5px;
        text-align: center;
      }
      .webProductButton{
        position: absolute;
        bottom:0;
      }
    }
  }
}

#product {
  #productTitle {
    text-align: center;
  }

  .swiperSlide {
    position: relative;

    img {
      width: 100%;
    }
  }
  #productContactButton {
    width: 100%;
    margin: 20px 0;
  }
}

#productVerzoek {
  text-align: center;

  .serviceVerzoekForm {
    margin-top: 30px;
    margin-bottom: 20px;

    .formTitle {
      margin-top: 20px;
    }

    .textInput {
      margin: 5px;
      width: 80%;
      .pickupButtons{
        width:50%;
      }
    }
  }
}

//Mobile/Non-global
@media screen and (max-width: 1200px) {
  #home {
    .swiperSlide {
      max-width: 100%;
      overflow: hidden;

      img {
        width: 150%;
        overflow: hidden;
        margin-left: -25%;
      }
    }
  }
  #product {
    .swiperSlide {
      max-width: 100%;
      overflow: hidden;
  
      img {
        width: 150%;
        overflow: hidden;
        margin-left: -25%;
      }
    }
  }
}



//Medium screen and Desktop screen styling
@media screen and (min-width: 1200px) {
  #banner {
    padding-top: 20px;

    #bannerDivider {
      display: none;
    }

    #fixedBanner {
      display: inline;
      width: 1200px;
      position: relative;
      margin-left: 100px;
      position: static;

      .menuIcon {
        display: none;
        position: static;
      }

      img {
        position: static;
      }

      #desktopText {
        width: 900px;
        text-align: right;
        display: inline-block;

        h3,
        h4 {
          margin: 0;
        }
      }
    }

    .mobileContactInfo {
      display: none;
    }

    .mobileText {
      display: none;
    }

    #desktopNav {
      display: block;
      width: 1200px;
      margin-left: calc(50% - 600px);
      margin-top: 20px;

      #desktopNavButtons {
        display: grid;
        justify-content: space-evenly;
        grid-template-columns: auto auto auto auto auto auto;

        .desktopNavButton {
          padding: 10px 0 !important;
          width: 200px;
          // width: ;
          margin: 0;
          padding: 0;
          font-size: 0.8rem;
        }
      }
    }
  }

  #home {
    margin-bottom: 550px; //float image height + 50

    #swiperComponent {
      margin-left: auto;
      margin-right: auto;
      width: 1200px;
    }
    #homeContent {
      #homeTextblock {
        margin: 20px;
        height: 320px;
      }

      .homeInfoCard {
        &.bottom {
          width: 32%;
          float: left;
          height: 500px;
          margin: 0;

          &.b {
            margin: 0 2%;
          }
        }
      }
    }
  }

  #services {
    margin: 20px 20px 20px 0;

    width: 350px;
    float: left;
  }

  #cilVlak {
    #cilVlakContent {
      #cilVlakBanner {
        display: block;
      }
    }
  }

  #cilVlakTransport {
    #cilVlakTransportContent {
      margin: 0 20px;

      #cilKopTransportImage {
        float: left;
        margin: 10px 20px 40px 0px;
      }

      #transportCards {
        display: grid;
        justify-content: space-evenly;
        grid-template-columns: auto auto auto;
        grid-gap: 0;

        #midCard {
          margin: 0 40px;
        }

        .sideCardContent {
          width: 380px;
          height: 400px;
          margin: 0;
        }

        .midCardContent {
          margin: 0;
          width: 380px;
          height: 400px;
        }

        .transportTitleCard {
          h2 {
            font-size: 1.8em;
          }
        }
      }
    }
  }

  #jachtOnderhoud {
    #jachtOnderhoudServiceCards {
      grid-template-columns: auto auto;
      justify-content: center;
    }

    .jachtOnderhoudServiceCard {
      width: 500px;
      margin: 5px;
      padding: 10px;

      img {
        width: 120%;
        margin-left: -10%;
        text-align: center;
      }

      &#bottomCard {
        margin: 5px auto;
      }
    }
  }

  #contact {
    .contactTab {
      margin: 16px 150px;

      ul {
        list-style: none;

        .contactListIcon {
          width: 1.5em;
          height: 1.5em;
        }
      }
    }
  }

  #serviceVerzoek {
    .halfWidthInput {
      width: calc(40% - 5px) !important;
    }
  }

  #webshop {
    #mainBody {
      grid-template-columns: auto auto auto;
    }
  }

  #product {
    #productDescriptionTitle{
      position: absolute;
      width: 70%;
    }
    
    #productDescription{
      margin-top:60px;
      position: absolute;
      width: 840px;
      // max-width: 70%;
      // overflow: hidden;
    }
    #productTechDetailsTitle{
      position: relative;
      left:70%;
      width:30%;
    }
    
    #productTechDetails{
      position: relative;
      left:70%;
      width:30%;
    }
    
    #productContactButton {
      margin-top:60px;
      position: relative;
      width: 840px;
      bottom:100px;
      // max-width: 70%;
      // overflow: hidden;
      // width: 100%;
      // margin: 20px 0;
    }
  }  
}
